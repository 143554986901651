import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.css";
import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ChatState } from "../../context/Context";
import { px } from "framer-motion";
import { WindowSharp } from "@mui/icons-material";

export default function Navbar() {
  //  --------------------
  const { menu_clicked, navbarView, setMenuClicked } = ChatState();
  const menuClicked = () => {
    setMenuClicked(!menu_clicked);
    console.log("menu clicked = ", menu_clicked);
  };

  const homeClickHandler = () => {
    setTimeout(() => {
      setIsSmallScreenActive(false);
      setIsNavBarSlideVisible(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 300);
  };

  const useCaseClickHandler = () => {
    setTimeout(() => {
      setIsSmallScreenActive(false);
      setIsNavBarSlideVisible(false);
      const section = document.querySelector("#infoSection");
      const section2 = document.querySelector("#infoSection2");
      if (section) {
        console.log("Section is executing");
        section.scrollIntoView({ behavior: "smooth" });
      }
      if (section2) {
        console.log("Section is executing another one");
        section2.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const productClickHandler = () => {
    setTimeout(() => {
      setIsSmallScreenActive(false);
      setIsNavBarSlideVisible(false);
      const section = document.querySelector("#videoSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const ContactClickHandler = (event) => {
    event.preventDefault(); // Prevent immediate navigation
    setTimeout(() => {
      setIsSmallScreenActive(false);
      setIsNavBarSlideVisible(false);
      window.location.href = "/contact";
    }, 300);
  };

  const BlogClickHandler = (event) => {
    event.preventDefault(); // Prevent immediate navigation
    setTimeout(() => {
      setIsSmallScreenActive(false);
      setIsNavBarSlideVisible(false);
      window.location.href = "/blog";
    }, 300);
  };
  //  --------------------
  let value = 0;
  useEffect(() => {
    const scrollNavbar = () => {
      let scroll = document.querySelector(`.${styles.navbarContainer}`);
      let Navbarbox = document.querySelector(`.${styles.Navbarbox}`);

      // checking whether usp section is in viewport
      const allFalse = navbarView.uspView || navbarView.videoSectionView;

      if (window.innerWidth > 576) {
        if (window.scrollY >= 300) {
          if (window.scrollY > value || allFalse) {
            scroll.style.transform = "translateY(-100%)";
            setMenuClicked(false);
            Navbarbox.classList.remove(`${styles.hovered}`);
          } else if (!allFalse) {
            setTimeout(function () {
              if (window.scrollY >= 300) {
                Navbarbox.classList.add(`${styles.hovered}`);
              }
            }, 1000);
            scroll.style.transform = "translateY(0)";
          }
          value = window.scrollY;
        } else {
          Navbarbox.classList.remove(`${styles.hovered}`);
          scroll.style.transform = "translateY(0)";
        }
      } else {
        if (window.scrollY >= 1) {
          if (window.scrollY > value || allFalse) {
            scroll.style.transform = "translateY(-100%)";
            setMenuClicked(false);
            Navbarbox.classList.remove(`${styles.hovered}`);
          } else if (!allFalse) {
            setTimeout(function () {
              if (window.scrollY >= 1) {
                Navbarbox.classList.add(`${styles.hovered}`);
              }
            }, 1000);
            scroll.style.transform = "translateY(0)";
          }
          value = window.scrollY;
        } else {
          Navbarbox.classList.remove(`${styles.hovered}`);
          scroll.style.transform = "translateY(0)";
        }
      }
    };

    const hamburgerMenuScroll = () => {
      let target = document.querySelector(`.${styles.buttonholder__menu}`);
      if (window.innerHeight <= window.scrollY) {
        target.style.background = "rgba(39, 42, 44, 0.5)";
        target.style.color = "#3ED2DC";
        for (let i = 0; i < target.children.length; i++) {
          target.children[i].style.textShadow = "0px 2px 3px black";
        }
      } else {
        target.style.background = "rgba(255, 250, 250, 0.18)";
        target.style.color = "rgba(32, 236, 247, 1)";
        for (let i = 0; i < target.children.length; i++) {
          target.children[i].style.textShadow = "none";
        }
      }
    };

    document.addEventListener("scroll", hamburgerMenuScroll);
    document.addEventListener("scroll", scrollNavbar);
    return () => {
      document.removeEventListener("scroll", scrollNavbar);
      document.removeEventListener("scroll", hamburgerMenuScroll);
    };
  }, []);

  const [isSmallScreenActive, setIsSmallScreenActive] = useState(false);
  const [isNavBarSlideVisible, setIsNavBarSlideVisible] = useState(false);

  const handleSidePushClick = () => {
    setIsSmallScreenActive(!isSmallScreenActive);
    setIsNavBarSlideVisible(!isNavBarSlideVisible);
  };

  const clickListener = (e) => {
    window.location.href= "https://aidiagnostic.thetalabs.ai"
  };
  const clickListener2 = (e) => {
    window.location.href= "https://calculator.thetalabs.ai"
  };

  return (  
    <AppBar position="fixed" className={styles.navbarContainer}>
      <Toolbar
        className={`${styles.Navbarbox} ${
          isSmallScreenActive ? styles.activeSmallScreen : ""
        }`}
      >
        <div className={styles.keeper}>
          <div className={styles.logowrapper}>
            <div className={styles.logo} />
          </div>
          <div className={styles.buttonholder}>
            <Button
              onClick={homeClickHandler}
              className={styles.holdertext}
              color="inherit"
            >
              Home
            </Button>
            <Button
              onClick={productClickHandler}
              className={styles.holdertext}
              color="inherit"
            >
              Product
            </Button>
            <Button
              onClick={useCaseClickHandler}
              className={styles.holdertext}
              color="inherit"
            >
              UseCase
            </Button>
          </div>
          <div className={styles.sidepush} onClick={handleSidePushClick}>
            <ul className={styles.navList}>
              {/* <Link className={styles.linkd} to="/blog">
              BLOG
            </Link> */}
              {/* -------------------------- */}
              <li className={styles.flex__props}>
                <Link className={styles.linkd} to="/blog">
                  BLOG
                </Link>
              </li>
              {/* -------------------------- */}
              <li className={styles.flex__props}>
                <Link className={styles.linkd} to="/contact">
                  CONTACT US
                </Link>
              </li>
              <li onClick={clickListener} className={styles.flex__props}>
                <a
                  className={styles.linkd}
                  href="https://aidiagnostic.thetalabs.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AI DIAGNOSIS
                </a>
              </li>
              <li onClick={clickListener2} className={styles.flex__props}>
                <a
                  className={styles.linkd}
                  href="https://calculator.thetalabs.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CALCULATOR
                </a>
              </li>
            </ul>
            <div className={styles.navbar__menu}>
              <Button
                className={styles.holdertext__menu}
                color="inherit"
                onClick={menuClicked}
              >
                <MenuIcon />
              </Button>
            </div>
          </div>
        </div>

        {/* ----------------------------576 px New Navbar ------------------------------------ */}
        {isNavBarSlideVisible && (
          <div className={styles.navbarslide}>
            <div className={styles.home} onClick={homeClickHandler}>
              HOME
            </div>
            <Link
              className={styles.linked}
              to="/blog"
              onClick={BlogClickHandler}
            >
              <div className={styles.blog}>BLOG</div>
            </Link>
            <div className={styles.usecase} onClick={useCaseClickHandler}>
              USECASE
            </div>

            <div className={styles.product} onClick={productClickHandler}>
              PRODUCT
            </div>
            <Link
              to="/contact"
              className={styles.linked}
              onClick={ContactClickHandler}
            >
              <div className={styles.contactus}>CONTACT US</div>
            </Link>
          </div>
        )}
      </Toolbar>

      {/* ------------------------------ */}
      <div className={`${styles.menu__container}`}>
        <div
          className={`${styles.buttonholder__menu}  ${
            menu_clicked && styles.menu__slider
          }`}
        >
          <Button
            onClick={homeClickHandler}
            className={styles.holdertext}
            color="inherit"
          >
            Home
          </Button>
          <Button
            onClick={productClickHandler}
            className={styles.holdertext}
            color="inherit"
          >
            Product
          </Button>
          <Button
            onClick={useCaseClickHandler}
            className={styles.holdertext}
            color="inherit"
          >
            UseCase
          </Button>
        </div>
      </div>
      {/* ------------------------------ */}
    </AppBar>
  );
}
